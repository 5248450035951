
import GeneralData from '../components/GeneralData.vue'
import useLocation from "@/modules/locations/composables/useLocation";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    props: ['id'],
    setup(props: { id: string }) {
        const {clearForm, locationForm, updateLocation, catalogs, getLocation} = useLocation()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();

        onMounted(async () => {
            locationForm.value = await getLocation(props.id)
            loading.value = false
        })

        return {
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'locations'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateLocation(locationForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'locations'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
